import React from 'react'
import Form from './Form'


function Hero() {
  return (
    <div className='hero'>
        <div className='overlay'></div>

        <div className='hero-body'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-6 d-flex align-items-center justify-content-center'>

                        <div className='hero-text'>
                            <h1 className='title text-white'>Get a Loan Today</h1>
                            <h2 className='subtitle text-white'>QUICK PROCESS TO HELP YOU NOW!</h2>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 d-flex align-items-center justify-content-center'>
                        <div className='hero-text'>
                           <Form />
                        </div>
                    </div>

                </div>
            </div>


        
        </div>
      
    </div>
  )
}

export default Hero
