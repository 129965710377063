import React from 'react'
import Icons from './Icons'
import OurPhoto from '../images/fl-title-loans.jpg'
import { Helmet } from 'react-helmet'

function AboutUs() {
  return (
    <section id='aboutus'>
        <Helmet>
            <title>About FLTitleLoan.com - Easy and Fast Title Loans in Florida</title>
            <meta name="description" content="Learn more about FLTitleLoan.com, your go-to source for fast and easy title loans in Florida. Our experienced team is dedicated to providing you with the cash you need when you need it." />

            <meta name="keywords" content="FLTitleLoan.com, about us, title loans, easy title loans, fast title loans, Florida title loans, cash loans." />
        </Helmet>

        <div className='hero-about'>
        <div className='overlay'></div>
            <div className='hero-body'>
                <div className='row'>
                    <div className='col-12 col-md-6 d-flex align-items-end justify-content-center'>

                        <div className='hero-text mb-5 text-start'>
                            <h1 className='title text-white'>About US</h1>
                            <h2 className='subtitle text-white'>FLORIDA TITLE LOANS, LLC</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container has-padding-top-bottom'>
            <div className='row'>
                <div className='col-xs-12 col-md-6 col-lg-4 pe-5 mb-4'>
                   <img src={OurPhoto} alt='FL Title Loan CEO' className='img-fluid border-radius' />
                </div>
                <div className='col-xs-12 col-md-6 col-lg-8 text-start'>
                    <h2 className='title'>Florida Title Loans, LLC</h2>
                    <p>FLTitleLoan.com is a leading provider of title loans, serving customers in Florida and beyond. We specialize in helping people who need quick access to cash but may not have the credit score or financial history required to secure a traditional bank loan. By using the title of your vehicle as collateral, we can provide you with a loan that fits your needs, whether you need to pay for an unexpected expense or cover your bills until your next paycheck.</p>

                    <p>Our team of loan specialists is knowledgeable, friendly, and always ready to help. We believe in making the loan process as easy as possible, which is why we offer a simple online application that can be completed in just minutes. We also offer flexible repayment options that allow you to pay back your loan on your own terms.</p>

                    <p>At FLTitleLoan.com, we understand that unexpected expenses can happen at any time, which is why we are available to assist you 7 days a week. Whether you need help with the application process, have questions about your loan, or need assistance with repayment, our team is here to help.</p>

                    <p>We take pride in providing exceptional customer service and treating our customers with respect and professionalism. We are fully licensed and insured, and we abide by all state and federal regulations regarding title loans.</p>

                    <p>If you need a loan and own a vehicle, FLTitleLoan.com is here to help. Contact us today to learn more about our loan options and how we can help you get the cash you need, quickly and easily.</p>
                    
                </div>
            </div>
        </div>
        <Icons background="icons-section gray-bg" />
    </section>
  )
}

export default AboutUs
