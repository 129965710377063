import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';

function Step1(props) {

  return (
    <>
    <div class="form-floating mb-3">  
    <input type="number" pattern="[0-9]*" inputmode="numeric" name='zip' placeholder='Type your zip code: ' id="floatingZip" className='form-control mb-4' onChange={props.handleZip} />
    <label for="floatingZip">* Type your Zip Code:</label>
    </div>  
    {props.errorMessage === true && props.zipCode === "" ? <div className='alert alert-warning'>You can not send an empty Zip Code</div> : ""}
    <div className='d-flex justify-content-end'>
    <button className='btn btn-success' onClick={props.checkZip}>
    {props.loading ? <>Processing
    <span className='material-symbols-sharp rotate'>
    change_circle
    </span></> : "Next" }
    </button>
    </div>
    </>

  )
}

export default Step1
