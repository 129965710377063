import React, { useState } from 'react'
import axios from 'axios'
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

function Form() {

  const [ step, setStep ] = useState(JSON.parse(localStorage.getItem('step')) || 0);

  const [ zipCode, setZipCode ] = useState(JSON.parse(localStorage.getItem('zipCode')) || "");

  const [ city, setCity ] = useState(JSON.parse(localStorage.getItem('city')) || '');

  const [ state, setState ] = useState(JSON.parse(localStorage.getItem('state')) ||'');



  const formTitle = ["CHECK IF YOU QUALIFY", "AWESOME, Approved!", "YOU APPLICATION WAS SUBMITED", "SORRY, YOUR STATE IS NOT INCLUDED." ]
  const formSubTitle = ["For a loan in your location", "Please share with us more info", "One of our representatives will call you.", "We only provide loans for the state of Florida." ]

  const formDisplay = () => {
    if (step === 0) {
      return <Step1 step={step} setStep={setStep} checkZip={checkZip} zipCode={zipCode} setZipCode={setZipCode} errorMessage={errorMessage} setErrorMessage={setErrorMessage} loading={loading} setLoading={setLoading} handleZip={handleZip} />
    } else if (step === 1){
      return <Step2 step={step} setStep={setStep} city={city} setCity={setCity} state={state} setState={setState} zipCode={zipCode} handleZip={handleZip} handleCity={handleCity} handleState={handleState} />
    } else if (step === 2){
      return <Step3 step={step} setStep={setStep} />
    } else if (step === 3){
      return <Step4 /> 
    }
  }





  const [ errorMessage, setErrorMessage ] = useState(false);

  const [ loading, setLoading ] = useState(false);


  const handleZip = (e) => {
    console.log(e.target.value)  
    setZipCode(e.target.value);
      if(e.target.value.length >= 5) {
          setErrorMessage(false);
      }

  }

  const handleCity = (e) => {
    setCity(e.target.value);
  }

  const handleState = (e) => {
    setState(e.target.value);
  }



  const options = {
    method: 'GET',
    url: 'https://us-zip-code-information.p.rapidapi.com/',
    params: {zipcode: `${zipCode}`},
    headers: {
      'content-type': 'application/octet-stream',
      'X-RapidAPI-Key': '60d8a2760bmshb7963e82b124735p173e03jsnab27911f181c',
      'X-RapidAPI-Host': 'us-zip-code-information.p.rapidapi.com'
    }
  };

  const checkZip = (e) => {
    e.preventDefault();
    if(zipCode === "") {
        return setErrorMessage(true);
    }
    setLoading(true);
    axios.get('https://us-zip-code-information.p.rapidapi.com/', options)
    .then(response => {
      console.log(response.data);
      setCity(response.data[0].City);
      setState(response.data[0].State);
      // Save to local Storage current state

      setLoading(false);
      if(response.data[0].State === "FL")  {
        localStorage.setItem("step", JSON.stringify(1));
        localStorage.setItem("zipCode", JSON.stringify(zipCode));
        localStorage.setItem("city", JSON.stringify(response.data[0].City));
        localStorage.setItem("state", JSON.stringify(response.data[0].State));
        return setStep(1);
      } else {
        return setStep(3);
      }
    })
    .catch(error => {
      console.error(error);
    });
  }




  return (
    <div className='form'>
        
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 p-0'>
                        <h2 className='title tertiary-color'>{formTitle[step]}</h2>
                        <h3 className='subtitle tertiary-color'>{formSubTitle[step]}</h3>
                        
                        {formDisplay()}
                        


                    </div>

                </div>
            </div>

       
    </div>
  )
}

export default Form
