import React from 'react'
import Hero from './Hero'
import Icons from './Icons'
import Steps from './Steps'
import Approve from './Approve'
import { Helmet } from 'react-helmet';

function Home() {
  return (
    <div>
        <Helmet>
            <title> FL Title Loans for Cars, Motorcycles, Trucks and More - Get Cash Fast | FLTitleLoan.com</title>
            <meta name="description" content="Need quick cash? FLTitleLoan.com offers easy title loans for cars, motorcycles, trucks, and more. Fill out our simple application form to get started today." />

            <meta name="keywords" content="FL title loans, car title loans, motorcycle title loans, truck title loans, vehicle title loans, easy title loans, fast cash." />
        </Helmet>
      <Hero />
      <Icons background="icons-section white-bg" />
      <Steps />
      <Approve />
    </div>
  )
}

export default Home
