import logo from './logo.svg';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Header from './Components/Header';
import Home from './Components/Home';
import Footer from './Components/Footer';
import AboutUs from './Components/AboutUs';
import Faq from './Components/Faq';
import ContactUs from './Components/ContactUs';
import ScrollToTop from './Components/ScrollToTop';
import TermsAndConditions from './Components/TermsAndConditions';

function App() {
  return (
    <div className="App">
      <Header />

      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
      </main>

      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default App;
