import React from 'react'

function Step4() {
  return (
    <div>
        <a href='/'>
        <button className='btn btn-primary mt-3'>Return to home.</button>
        </a>
    </div>
  )
}

export default Step4
