import React from 'react'
import Icons from './Icons'
import OurPhoto from '../images/fl-title-loans.jpg'
import { useState } from 'react';
import { Helmet } from 'react-helmet';

function Faq() {

    const questions = [
        {
            question : "If I want to pay off my loan early is there a prepayment penalty?",
            answer : "No, we have no prepayment penalties."
        },
        {
            question : "What do I need to get a title loan?",
            answer : "The title of the car has to be in Florida with your name and with no Liens.   A current ID such as a Driver’s License from the State of Florida.   The car must be operable and in working condition.  If it is at a repair shop, we send the funds directly for the repair.  A proof of address such as a recent bill with your name and address will be required."
        },
        {
            question : "How much money can I get?",
            answer : "The amount of money you can borrow is based on the value of your car, and can range anywhere from $1,000 to $25,000. "
        },
        {
            question : "Will I be able to keep driving my car?",
            answer : "Yes, you will keep your car the entire time.  When you finish making your payments, you receive your title back. "
        },
        {
            question : "Do you charge anything if I don’t qualify for the loan?",
            answer : "No, we will not charge you any fees."
        },
        {
            question : "How long does the process take?",
            answer : "If you have all of your information, including your car title, registration, drivers license, and a recent bill with your name and address you can have access to your money in less than 30 mins."
        },
        {
            question : "When is my first payment?",
            answer : "Your first payment will be due 30 days after you get your loan. "
        },
        {
            question : "How long is your loan?",
            answer : "We offer a 12 month loan. "
        },
        {
            question : "Can I apply after hours?",
            answer : "Yes, we receive and process online applications 24/7, and conduct inspections from 8 AM to 8 PM Monday- Friday and Saturday’s from 9 AM-5 PM."
        }

    ];
    
    const handleAnswer = (e) => {

        const answer = e.target.parentNode.querySelector('.card-body');
        const arrow = e.target.parentNode.querySelector('.material-symbols-sharp');

        answer.classList.toggle('isVisible');
        arrow.classList.toggle('flip');

    }


    

  return (
    <section id='Faq'>
        <Helmet>
            <title>Frequently Asked Questions About FL Title Loans - FLTitleLoan.com</title>
            <meta name="description" content="Have questions about FL title loans? Check out our FAQ page for answers to common questions about our fast and easy title loans for cars, motorcycles, trucks, and more." />

            <meta name="keywords" content="FL title loans, frequently asked questions, FAQ, easy title loans, fast title loans, car title loans, motorcycle title loans, truck title loans." />
        </Helmet>
        <div className='hero-about'>
        <div className='overlay'></div>
            <div className='hero-body'>
                <div className='row'>
                    <div className='col-12 col-md-6 d-flex align-items-end justify-content-center'>

                        <div className='hero-text mb-5 text-start'>
                            <h1 className='title text-white'>FAQ</h1>
                            <h2 className='subtitle text-white'>Frequently Asked Questions About Title Loans in Florida</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container'>
            {questions && questions.map((question) => ( <div className='card mt-3 mb-3'>
                                                            <div className='card-header' onClick={handleAnswer}><h2>{question.question}</h2> <span className="material-symbols-sharp tertiary-color">keyboard_arrow_up</span></div>
                                                            <div className='card-body'> <p>{question.answer}</p></div>
                                                           
                                                        </div>
                                                       )
                                        )}
        </div>
        <Icons background="icons-section gray-bg" />
    </section>
  )
}

export default Faq
