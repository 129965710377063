import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

function Approve() {

  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
    window.scrollTo(0, 0);
  }

  return (
    <section className='approve'>
      <div className='container'>
        <h2 className='title tertiary-color'>Get Approved Today!</h2>
        <div className='d-flex justify-content-center'>
          <button className='btn btn-success' onClick={goToHome}> GOT TO THE APPLICATION</button>
        </div>
      </div>
    </section>
  )
}

export default Approve
