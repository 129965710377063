import React from 'react'
import { useNavigate } from 'react-router-dom';

function Icons(props) {

  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
    window.scrollTo(0, 0);
  }

  return (
    <section className={props.background}>
        <div className='container'>
            <h2 className='title quartiary-color mb-4'>we provide loans for all titles of vehicles in florida</h2>
            <div className='icons'>
                <div className='icon-wrap'>
                <span className="material-symbols-sharp primary-color">directions_car</span>
                <p className='primary-color'>Florida Car Title Loans</p>
                </div>
                <div className='icon-wrap'>

                <span className="material-symbols-sharp secondary-color">airport_shuttle</span>
                <p className='secondary-color'>Florida SUV Title Loans</p>
                </div>
                <div className='icon-wrap'>

                <span className="material-symbols-sharp tertiary-color">two_wheeler</span>
                <p className='tertiary-color'>Florida Bike Title Loans</p>
                </div>
                <div className='icon-wrap'>

                <span className="material-symbols-sharp quartiary-color" >local_shipping</span>
                <p className='quartiary-color'>Florida Truck Title Loans</p>
                </div>
            </div>
          <div className='d-flex justify-content-center mt-5'>
          <button className='btn btn-success' onClick={goToHome}>Get Started</button>
          </div>
        </div>

    </section>
  )
}

export default Icons
