import React, { useRef, useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import axios from 'axios';


function Step2(props) {

    const form = useRef();
  
    const [ sentEmail, setSentEmail ] = useState(false);
    
    const [ emailEnviado, setEmailEnviado ] = useState(false);

    const [makes, setMakes ] = useState(['Buick', 'MINI', 'Volvo', 'Ford', 'HUMMER', 'GMC', 'Subaru', 'Mitsubishi', 'Dodge', 'Nissan', 'Honda', 'Lincoln', 'Hyundai', 'BMW', 'Bentley', 'Lexus', 'Chevrolet', 'Jaguar', 'Mercedes-Benz', 'Volkswagen', 'Aston Martin', 'Land Rover', 'Pontiac', 'Cadillac', 'FIAT', 'Saab', 'Kia', 'Lamborghini', 'Audi', 'Jeep', 'MAZDA', 'Suzuki', 'Toyota', 'Acura', 'Saturn', 'Chrysler', 'Isuzu', 'Ferrari', 'Tesla', 'INFINITI', 'Oldsmobile', 'Ram', 'Eagle', 'Porsche', 'Mercury', 'Scion', 'Lotus', 'Plymouth', 'Freightliner', 'Rolls-Royce', 'SRT', 'Maybach', 'Alfa Romeo', 'Geo', 'smart', 'Daewoo', 'Maserati', 'Daihatsu', 'Genesis', 'McLaren', 'Fisker', 'Panoz']);

    const [make, setMake ] = useState("");

    const [ year, setYear ] = useState(0);
    
    const [models, setModels ] = useState([]);

    const [model, setModel ] = useState("");

    const [ loading, setLoading ] = useState(false);

    const sendEmail = (e) => {
      e.preventDefault();
      setLoading(true);
  
      emailjs.sendForm(process.env.REACT_APP_SERVE_FORM, process.env.REACT_APP_TEMPLATE_FORM, form.current, process.env.REACT_APP_KEY_FORM)
        .then((result) => {
            console.log(result.text);
            setSentEmail(true);
            setEmailEnviado(true);
            handleEmailSent();
            props.setStep(2);
            localStorage.removeItem("step");
            localStorage.removeItem('zipCode');
            localStorage.removeItem('city');
            localStorage.removeItem('state');
            setLoading(false);
        }, (error) => {
            console.log(error.text);
        });
    };

    const handleEmailSent = () => {
        document.getElementById('contact-form').reset();
        setSentEmail(false);
    }
    

    const emailSubmit = () => {
        setEmailEnviado(false);
    }

    const handleMake = (e) => {
        setMake(e.target.value);
      };

      const handleYear = (e) => {
        setYear(e.target.value);
        getModels();
      };

      const handleModel = (e) => {
        setModel(e.target.value);
      };

    const makeOptions = {
        method: 'GET',
        url: 'https://car-data.p.rapidapi.com/cars/makes',
        headers: {
          'content-type': 'application/octet-stream',
          'X-RapidAPI-Key': '60d8a2760bmshb7963e82b124735p173e03jsnab27911f181c',
          'X-RapidAPI-Host': 'car-data.p.rapidapi.com'
        }
      };

      const modelOptions = {
        method: 'GET',
        url: 'https://car-data.p.rapidapi.com/cars',
        params: {
          limit: '30',
          page: '0',
          year: `${year}`,
          make: `${make}`
        },
        headers: {
          'content-type': 'application/octet-stream',
          'X-RapidAPI-Key': '60d8a2760bmshb7963e82b124735p173e03jsnab27911f181c',
          'X-RapidAPI-Host': 'car-data.p.rapidapi.com'
        }
      };

    useEffect(()=>{

        axios.get('https://car-data.p.rapidapi.com/cars/makes', makeOptions)
        .then(response => {
          console.log(response.data);
          const data = response.data;
          const sortData = data.sort((a,b)=> a > b ? 1 : -1);
          setMakes(sortData);
        })
        .catch(error => {
          console.error(error);
        });

    },[])

    const getModels = () => {
        
        axios.get('https://car-data.p.rapidapi.com/cars', modelOptions)
        .then(response => {
          console.log(response.data);
          const data = response.data;
          const sortData = data.sort((a,b)=> a.model > b.model ? 1 : -1);
          setModels(sortData);
        })
        .catch(error => {
          console.error(error);
        });
        

    }

    const editLocation = (e) => {
        const selectLocation = document.querySelector('.location');
        selectLocation.classList.toggle('is-hidden');
        console.log(e.target.innerHTML)
        if(e.target.innerHTML !== "Hide Location Fields" ) {
         e.target.innerHTML = "Hide Location Fields";
         e.target.classList.remove('btn-primary');
         e.target.classList.add("btn-warning");
        } else {
            e.target.innerHTML = "Update Location (Not required)"
            e.target.classList.remove('btn-warning');
            e.target.classList.add("btn-primary");
        }
    }

  return (
    <>
            <form ref={form} onSubmit={sendEmail} id="contact-form">
            <div className='row'>
                <div className='col-6 ps-0'>
                    <input type="text" name="from_name" className='form-control mb-3' placeholder='Name' required />
                </div>
                <div className='col-6 ps-0 pe-0'>
                    <input type="text" name="from_lastname" className='form-control mb-3' placeholder='Last Name' required />
                </div>
            </div>

            <div className='row'>
                <div className='col-6 ps-0'>
                    <input type="email" name="from_email" className='form-control mb-3' placeholder='E-mail' required />
                </div>
                <div className='col-6 ps-0 pe-0'>
                    <input type="tel" name="from_phone" className='form-control mb-3' placeholder='Phone number' required />
                </div>
            </div>

            <div className='row'>
                <div className='col-6 ps-0'>
                    <select
                        id="inputGroupSelect04"
                        className="form-select"
                        aria-label="Example select with button addon"
                        name="from_make"
                        onChange={handleMake}
                        >
                        <option value="all">Select a Make</option>
                        {makes &&
                            makes.map((make, index) => (
                            <option key={index} value={make}>
                                {make}
                            </option>
                            ))}
                    </select>
                </div>
                <div className='col-6 ps-0  pe-0'>
                    <select name="from_year" class='form-select mb-3' onChange={handleYear} required>
                        <option value="">Car Year</option>
                        {
                            Array.from({length: new Date().getFullYear() - 1980 + 1}, (_, i) =>
                            <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                            )
                        }
                    </select>
                </div>
            </div>

            <div className='row m-0 ps-0'>
                <div className='col-6 m-0 ps-0'>
                {makes && make !== "" && year !== 0 ? 
                    <select
                        id="inputGroupSelect04"
                        className="form-select"
                        aria-label="Example select with button addon"
                        name="from_model"
                        onChange={handleModel}
                        >
                        <option value="all">Select Model</option>
                        {models &&
                            models.map((model) => (
                            <option key={model.id} value={model.model}>
                                {model.model}
                            </option>
                            ))}
                    </select>: "Select a make first." }
                    
                </div>
                <div className='col-6 ps-0  pe-0'>
                    <input type="text" name="from_mileage" className='form-control mb-3' placeholder='Car Mileage' required />
                </div>
            </div>
            <div className='box'>
                <a className='btn btn-secondary mb-3' onClick={editLocation}>Your Location</a>
            </div>

            <div className='location is-hidden'>
            <div className='row'>
                <div className='col-6'>
                    <input type="text" name="from_zipcode" className='form-control mb-3' value={props?.zipCode} onChange={props.handleZip} placeholder='Zip Code:' required />
                </div>
                <div className='col-6'>
                    <input type="text" name="from_city" className='form-control mb-3' placeholder='City' onChange={props.handleCity} value={props?.city} required />
                </div>
            </div>

            <div className='row'>
                <div className='col-12'>
                    <input type="text" name="from_state" className='form-control mb-3' placeholder='State:' onChange={props.handleState}  value={props?.state} required />
                </div>
            </div>
            </div>

            <div class="form-floating mb-3">
            <textarea name="message"  class="form-control" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
            <label for="floatingTextarea">Comments</label>
            </div>
            <p>By Submiting this form you agree to our <a href="/terms" target="_blank">Terms and Conditions.</a></p>
            <div className='text-end'>
            <button type="submit" onClick={()=> setTimeout(emailSubmit, 10000)} className={emailEnviado? 'btn btn-success' : 'btn btn-primary' } id="button-form">
            {emailEnviado? <span className="material-symbols-sharp">check_circle</span> : null}
            {loading? <span className='material-symbols-sharp rotate'>change_circle</span>: null}
            {emailEnviado? 'E-mail Sent!' : 'Submit' } 
            </button>
            </div>
            </form>
      
    </>
  )
}

export default Step2
