import React from 'react'
import Icons from './Icons'
import HeroContact from './HeroContact'
import { Helmet } from 'react-helmet'

function ContactUs() {
  return (
    <section id='contact'>
            <Helmet>
                <title>Contact FLTitleLoan.com - Fast and Easy Title Loans in Florida</title>
                <meta name="description" content="Contact FLTitleLoan.com today for fast and easy title loans in Florida. Fill out our simple contact form or give us a call to get started on getting the cash you need." />

                <meta name="keywords" content="Contact FLTitleLoan.com, title loans, fast title loans, easy title loans, Florida title loans, cash loans." />
            </Helmet>
            <HeroContact />
            <Icons background="icons-section gray-bg" />
    </section>

  )
}

export default ContactUs
