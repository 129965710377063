import React from 'react'

function Step3() {
  return (
    <div>
      <img src='./winner.gif' className='img-fluid' alt='You made it!'/>
    </div>
  )
}

export default Step3
