import React from 'react'

function Steps() {
  return (
    <section className='steps'>
      <div className='container'>
        <h2 className='title text-white'>How it works</h2>
        <h3 className='subtitle text-white'>three easy steps</h3>
        <div className='steps-columns'>
          <div className='step-wrap'>
            <span className='number'>1</span>
            <h4 className='subtitle text-white'>apply now</h4>
            <p className='text-white'>Apply now and Complete online Application or Call us NOW</p>
          </div>
          <div className='step-wrap'>
            <span className='number'>2</span>
            <h4 className='subtitle text-white'>get approved</h4>
            <p className='text-white'>Get Approved and we’ll inspect the Vehicle from wherever you are you only need your mobile device (cell phone, tablet, other)</p>
          </div>
          <div className='step-wrap'>
            <span className='number'>3</span>
            <h4 className='subtitle text-white'>get your cash</h4>
            <p className='text-white'>Get your CASH sent directly to your bank, debit card, or just pick it up a MoneyGram location.</p>
          </div>

        </div>
      </div>
    </section>
  )
}

export default Steps
