import React, { useRef, useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import axios from 'axios';



function FormContact() {

    const form = useRef();
  
    const [ sentEmail, setSentEmail ] = useState(false);
    
    const [ emailEnviado, setEmailEnviado ] = useState(false);

    const [ loading, setLoading ] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);
    
        emailjs.sendForm(process.env.REACT_APP_SERVE_FORM, process.env.REACT_APP_TEMPLATE_FORM_CONTACT, form.current, process.env.REACT_APP_KEY_FORM)
          .then((result) => {
              console.log(result.text);
              setSentEmail(true);
              setEmailEnviado(true);
              handleEmailSent();
              setLoading(false);
          }, (error) => {
              console.log(error.text);
              setLoading(false);
          });
      };

      const handleEmailSent = () => {
        document.getElementById('contact-form').reset();
        setSentEmail(false);
    }
    

    const emailSubmit = () => {
        setEmailEnviado(false);
    }

  return (



<div className='form'>
        
<div className='container-fluid'>
    <div className='row'>
        <div className='col-12'>
            <h2 className='title tertiary-color'>SEND US A MESSAGE</h2>
            <h3 className='subtitle tertiary-color'>IF YOU HAVE ANY PROBLEMS PLEASE CONTACT US</h3>

            <div>
            <div className='row'>
                <form ref={form} onSubmit={sendEmail} id="contact-form">
                <div class="form-floating mb-3">
                    <input type="text" name="from_name" className='form-control mb-3' placeholder='Name' id="floatingName" required />
                    <label for="floatingName">* Name</label>
                </div>
                <div class="form-floating mb-3">
                    <input type="tel" name="from_phone" className='form-control mb-3'  id="floatingPhone" placeholder='Phone number' required />
                    <label for="floatingPhone">* Mobile Phone</label>

                </div>
                <div class="form-floating mb-3">
                    <input type="email" name="from_email" className='form-control mb-3' id='floatingEmail' placeholder='E-mail' required />
                    <label for="floatingEmail">* Email:</label>
                </div>


                    <div class="form-floating mb-3">
                    <textarea name="message"  class="form-control" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                    <label for="floatingTextarea">Message:</label>
                    </div>
                    <div className='text-end'>
                    <button type="submit" onClick={()=> setTimeout(emailSubmit, 10000)} className={emailEnviado? 'btn btn-success' : 'btn btn-primary' } id="button-form">
                    {emailEnviado? <span className="material-symbols-sharp">check_circle</span> : null}
                    {loading? <span className='material-symbols-sharp rotate'>change_circle</span>: null}
                    {emailEnviado? 'E-mail Sent!' : 'Submit' } 
                    </button>
                    </div>
                    </form>
            </div>
    </div>
            


        </div>

    </div>
</div>


</div>
  )
}

export default FormContact
