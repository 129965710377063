import React from 'react'
import Icons from './Icons'
import OurPhoto from '../images/fl-title-loans.jpg'
import { Helmet } from 'react-helmet'

function TermsAndConditions() {
  return (
    <section id='terms'>
        <Helmet>
            <title>FLTitleLoan.com Terms and Conditions - Authorization to Share Contact Information with Banks</title>
            <meta name="description" content="Read FLTitleLoan.com's terms and conditions before submitting your application for a fast and easy title loan in Florida. By submitting your application, you authorize us to share your contact information with banks that can provide you with a loan." />

            <meta name="keywords" content="FLTitleLoan.com, terms and conditions, authorization, contact information, banks, title loans, fast title loans, easy title loans, Florida title loans." />
        </Helmet>

        <div className='hero-about'>
        <div className='overlay'></div>
            <div className='hero-body'>
                <div className='row'>
                    <div className='col-12 col-md-6 d-flex align-items-end justify-content-center'>

                        <div className='hero-text mb-5 text-start'>
                            <h1 className='title text-white'>Terms and Conditions</h1>
                            <h2 className='subtitle text-white'>Please read our Terms and Conditions</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container has-padding-top-bottom'>
            <div className='row'>

                <div className='col-xs-12 col-md-6 col-lg-8 text-start'>
                    <h2 className='title'>Terms and Conditions:</h2>
                    <p>By submitting your application for a title loan on FLTitleLoan.com, you authorize us to share your contact information with banks that can provide you with a loan. This information may include your name, phone number, email address, and any other information you provide to us.</p>

                    <p>We take your privacy seriously and will only share your information with banks that we trust and have a relationship with. These banks may contact you directly to discuss your loan options and provide you with loan terms and conditions.</p>

                    <p>Please note that we do not guarantee that you will be approved for a loan or that you will receive loan terms that meet your expectations. The decision to approve your loan and the terms and conditions of your loan are solely up to the bank that you choose to work with.</p>

                    <p>By submitting your application on FLTitleLoan.com, you acknowledge that you have read and agree to these terms and conditions. If you do not agree with these terms and conditions, please do not submit your application.</p>
                    
                </div>
            </div>
        </div>
        <Icons background="icons-section gray-bg" />
    </section>
  )
}

export default TermsAndConditions
